// banner
export const HH_DESIGN_SYSTEM_BANNER_TITLE = 'Design Tokens';
export const HH_DESIGN_SYSTEM_BANNER_SUBTITLE = 'Building a design token system from the ground up for a web platform';
export const HH_DESIGN_SYSTEM_BANNER_ALT = 'An illustration showcasing the home page of the Hometown Heroes Community website';

//overview section
export const HH_DESIGN_SYSTEM_OVERVIEW_TITLE1 = 'My Role';
export const HH_DESIGN_SYSTEM_OVERVIEW_SUBTITLE1 = 'Design System Lead';
export const HH_DESIGN_SYSTEM_OVERVIEW_TITLE2 = 'Collaborators';
export const HH_DESIGN_SYSTEM_OVERVIEW_SUBTITLE2 = 'Product Designers, Product Manager';
export const HH_DESIGN_SYSTEM_OVERVIEW_TITLE3 = 'Project Duration';
export const HH_DESIGN_SYSTEM_OVERVIEW_SUBTITLE3 = '8 Weeks';
export const HH_DESIGN_SYSTEM_OVERVIEW_TITLE4 = 'Tools';
export const HH_DESIGN_SYSTEM_OVERVIEW_SUBTITLE4 = 'Figma';

//content sections
export const HH_DESIGN_SYSTEM_SECTION_OVERVIEW_TITLE = 'Overview';
export const HH_DESIGN_SYSTEM_SECTION_OVERVIEW_CONTENT = "Hometown Heroes Community is a web platform that connects artists and musicians with mentorship and career opportunities. I developed the design system, defining foundations and creating a component library to ensure cohesion and scalability. This case study explores how I created the design tokens to establish a foundation for consistent and efficient design.";

export const HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_TITLE = 'Contributions';
export const HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_NUMBER1 = '01';
export const HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE1 = 'Developed the design tokens from scratch, establishing a clear structure and intuitive nomenclature.';
export const HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_NUMBER2 = '02';
export const HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE2 = 'Set up tokens using variables in Figma, enabling consistent theming for light and dark modes across the platform.';
export const HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_NUMBER3 = '03';
export const HH_DESIGN_SYSTEM_SECTION_CONTRIBUTIONS_BULLET_SUBTITLE3 = 'Engaged with stakeholders to understand their needs, facilitating the effective implementation of tokens and ensuring their appropriate usage.';

export const HH_DESIGN_SYSTEM_SECTION_IMPACT_TITLE = 'Impact';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_NUMBER1 = '01';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_TITLE1 = 'Timely delivery';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_SUBTITLE1 = 'Reusable tokens and components enabled fast delivery of high-fidelity designs within a short timeframe.';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_NUMBER2 = '02';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_TITLE2 = 'Consistent and high-quality UI';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_SUBTITLE2 = 'Established a unified design language that ensures a cohesive and polished user experience.';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_NUMBER3 = '03';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_TITLE3 = 'Scalability for future growth';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_BULLET_SUBTITLE3 = 'Built a flexible design system that is prepared to scale and evolve in response to future needs.';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_QUOTE1 = 'The design tokens were incredibly easy to understand and apply, which streamlined our design process. This allowed us to work faster and efficiently within the time constraints.';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_NAME1 = 'Sanjana Danait';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_ROLE1 = 'Product Designer';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_ALT1 = 'Photo of Sanjana Danait';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_QUOTE2 = 'The design tokens ensured timely delivery by providing a clear, consistent framework. They also set us up for future scalability while keeping design integrity across the product.';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_NAME2 = 'Utkarsh Chhadva';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_ROLE2 = 'Product Manager';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_TESTIMONIAL_ALT2 = 'Photo of Utkarsh Chhadva';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_METRIC_NUMBER = '99%';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_METRIC_LABEL = 'Consistency Score';
export const HH_DESIGN_SYSTEM_SECTION_IMPACT_CONTENT = 'The implementation of design tokens resulted in an extremely consistent interface, indicating that the majority of design components adhered to the established visual language.';

export const HH_DESIGN_SYSTEM_SECTION_PROCESS_TITLE = 'The Process';

export const HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_TITLE = 'Problems to be solved';
export const HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_LABEL = 'Challenge';
export const HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_NUMBER1 = '01';
export const HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_TITLE1 = 'Establish clear purpose';
export const HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_SUBTITLE1 = 'Ensure consistency and eliminate ambiguity among designers.';
export const HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_NUMBER2 = '02';
export const HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_TITLE2 = 'Enable scalability';
export const HH_DESIGN_SYSTEM_SECTION_OBJECTIVES_CONTENT_SUBTITLE2 = 'Prepare the system for seamless future updates and growth.';

export const HH_DESIGN_SYSTEM_SECTION_RESEARCH_TITLE = 'Insights from established design systems';
export const HH_DESIGN_SYSTEM_SECTION_RESEARCH_LABEL = 'Research';
export const HH_DESIGN_SYSTEM_SECTION_RESEARCH_ALT = 'A graphical representation of design tokens in Atlassian Design System, Material 3, and Fluent 2';
export const HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_NUMBER1 = '01';
export const HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_SUBTITLE1 = 'Tokens are organized by levels and hierarchy.';
export const HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_NUMBER2 = '02';
export const HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_SUBTITLE2 = 'Hierarchy is determined by system complexity and requirements.';
export const HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_NUMBER3 = '03';
export const HH_DESIGN_SYSTEM_SECTION_RESEARCH_BULLET_SUBTITLE3 = 'Consistent naming patterns define token structure.';

export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_TITLE = 'Factors to keep in mind';
export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_LABEL = 'Key Considerations';
export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_NUMBER1 = '01';
export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_TITLE1 = 'Time constraints';
export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_SUBTITLE1 = 'Limited time was available for implementation.';
export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_NUMBER2 = '02';
export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_TITLE2 = 'Parallel design efforts';
export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_SUBTITLE2 = 'Different designers were working on various features simultaneously.';
export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_NUMBER3 = '03';
export const HH_DESIGN_SYSTEM_SECTION_CONSTRAINTS_BULLET_SUBTITLE3 = 'Limited understanding of tokens';

export const HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_TITLE = 'Guiding values for tokens';
export const HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_LABEL = 'Principles';
export const HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_NUMBER1 = '01';
export const HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_TITLE1 = 'Simplicity';
export const HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_SUBTITLE1 = 'Easy to understand';
export const HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_NUMBER2 = '02';
export const HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_TITLE2 = 'Limited number';
export const HH_DESIGN_SYSTEM_SECTION_PRINCIPLES_CONTENT_SUBTITLE2 = 'Avoid too many tokens';

export const HH_DESIGN_SYSTEM_SECTION_LEVELS_TITLE = 'Establishing token levels';
export const HH_DESIGN_SYSTEM_SECTION_LEVELS_LABEL = 'Token Hierarchy';
export const HH_DESIGN_SYSTEM_SECTION_LEVELS_ALT = 'A pictorial depiction of token levels: raw value, primitive token, and semantic tokens';

export const HH_DESIGN_SYSTEM_SECTION_STRUCTURE_TITLE = 'Defining the naming convention';
export const HH_DESIGN_SYSTEM_SECTION_STRUCTURE_LABEL = 'Token Structure';
export const HH_DESIGN_SYSTEM_SECTION_STRUCTURE_ALT = 'A pictorial depiction of naming convention of tokens: element-property-variant-state';
export const HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_TITLE1 = 'Element';
export const HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_SUBTITLE1 = 'Indicates a specific aspect such as text, background, border, or icon.';
export const HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_TITLE2 = 'Variant';
export const HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_SUBTITLE2 = 'Reflects the hierarchy to guide usage, such as primary, secondary, or tertiary.';
export const HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_TITLE3 = 'State';
export const HH_DESIGN_SYSTEM_SECTION_STRUCTURE_CONTENT_SUBTITLE3 = 'Optional designation used for some tokens based on specific use cases.';

export const HH_DESIGN_SYSTEM_SECTION_SPECIAL_CASES_TITLE = 'Tokens for specific use cases';
export const HH_DESIGN_SYSTEM_SECTION_SPECIAL_CASES_LABEL = 'Special Cases';
export const HH_DESIGN_SYSTEM_SECTION_SPECIAL_CASES_ALT = 'A pictorial depicition of a list of tokens for specific use cases';

export const HH_DESIGN_SYSTEM_SECTION_DARK_MODE_TITLE = 'Adapting tokens for light and dark modes';
export const HH_DESIGN_SYSTEM_SECTION_DARK_MODE_LABEL = 'Theming';
export const HH_DESIGN_SYSTEM_SECTION_DARK_MODE_ALT = 'A pictorial depicition of a list of tokens for light and dark mode';
export const HH_DESIGN_SYSTEM_SECTION_DARK_MODE_ALT2 = 'A pictorial depicition of a button in light and dark mode';

export const HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_TITLE = 'Applying tokens to the interface';
export const HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_LABEL = 'Tokens in Action';
export const HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_LIGHT_TITLE = 'Light mode for product pages';
export const HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_LIGHT_ALT = 'A pictorial depicition of tokens applied on the interface in light mode';
export const HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_DARK_TITLE = 'Dark mode for marketing pages';
export const HH_DESIGN_SYSTEM_SECTION_TOKENS_IN_ACTION_DARK_ALT = 'A pictorial depicition of tokens applied on the interface in dark mode';

export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_TITLE='Learnings';
export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_NUMBER1='01';
export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_TITLE1='Keeping designers at the forefront';
export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_SUBTITLE1='Tokens should be organized in a way that genuinely aids designers, making their workflow more efficient and intuitive.';
export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_NUMBER2='02';
export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_TITLE2='Token scoping ensures ease of use';
export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_SUBTITLE2="Figma's token scoping feature helps limit tokens to specific design elements, enhancing clarity and preventing misuse.";
export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_NUMBER3='03';
export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_TITLE3='Growth as a design system designer';
export const HH_DESIGN_SYSTEM_SECTION_LEARNINGS_SUBTITLE3="I deepened my understanding of design tokens, gaining invaluable experience in driving consistency and efficiency.";




// banner
export const CRUDE_BANNER_TITLE = 'Crude Design System';
export const CRUDE_BANNER_SUBTITLE = 'Creating a general purpose, neubrutalist design system for web platforms';
export const CRUDE_BANNER_ALT = 'An illustration showcasing color accessibility sheet, color palette, few components, and type scale of Crude design system';

// overview section
export const CRUDE_OVERVIEW_TITLE1 = 'My Role';
export const CRUDE_OVERVIEW_SUBTITLE1 = 'Visual Designer';

export const CRUDE_OVERVIEW_TITLE2 = 'Mentor';
export const CRUDE_OVERVIEW_SUBTITLE2 = "Prof. Jason Aston";

export const CRUDE_OVERVIEW_TITLE3 = 'Project Duration';
export const CRUDE_OVERVIEW_SUBTITLE3 = '6 Weeks';

export const CRUDE_OVERVIEW_TITLE4 = 'Tools';
export const CRUDE_OVERVIEW_SUBTITLE4 = 'Figma';

// content sections
export const CRUDE_SECTION_OVERVIEW_TITLE = 'Overview';
export const CRUDE_SECTION_OVERVIEW_CONTENT = 'As a part of the Visual Design course, I created a design system. With specific design principles in mind, I defined every single aspect of the design system, and laid out the foundations and related guidelines. Based on this, I created some basic components and patterns. To demonstrate the design system in use, I created a concept website that uses these components and patterns.';

export const CRUDE_SECTION_DELIVERABLE_TITLE = 'Final deliverable';
export const CRUDE_SECTION_DELIVERABLE_CONTENT = 'The final outcome of this project is a design system named Crude. Following aspects of Crude are available:';
export const CRUDE_SECTION_DELIVERABLE_BULLET_NUMBER1 = '01';
export const CRUDE_SECTION_DELIVERABLE_BULLET_SUBTITLE1 = 'Principles';
export const CRUDE_SECTION_DELIVERABLE_BULLET_NUMBER2 = '02';
export const CRUDE_SECTION_DELIVERABLE_BULLET_SUBTITLE2 = 'Foundations';
export const CRUDE_SECTION_DELIVERABLE_BULLET_NUMBER3 = '03';
export const CRUDE_SECTION_DELIVERABLE_BULLET_SUBTITLE3 = 'Components and patterns';
export const CRUDE_SECTION_DELIVERABLE_LINK_LABEL = 'View Crude Design System';

export const CRUDE_SECTION_IMPACT_TITLE = 'Impact';
export const CRUDE_SECTION_IMPACT_CONTENT = 'Crude design system is live and available on Figma Community for anyone to use. With more and more designers using Crude and taking inspiration from this design system, I am proud that my work is helping others.';
export const CRUDE_SECTION_IMPACT_METRIC_NUMBER1 = '800+';
export const CRUDE_SECTION_IMPACT_METRIC_LABEL1 = 'Users';
export const CRUDE_SECTION_IMPACT_METRIC_NUMBER2 = '2000+';
export const CRUDE_SECTION_IMPACT_METRIC_LABEL2 = 'Views';
export const CRUDE_SECTION_IMPACT_METRIC_NUMBER3 = '30+';
export const CRUDE_SECTION_IMPACT_METRIC_LABEL3 = 'Saves';
export const CRUDE_SECTION_IMPACT_LINK_LABEL = 'Visit Figma Community Page';

export const CRUDE_SECTION_PROCESS_TITLE = 'The Process';

export const CRUDE_SECTION_WHY_TITLE = 'Why a design system?';
export const CRUDE_SECTION_WHY_BULLET_NUMBER1 = '01';
export const CRUDE_SECTION_WHY_BULLET_SUBTITLE1 = 'Design system lies at the intersection of tech and design, which makes it interesting as well as challenging piece.';
export const CRUDE_SECTION_WHY_BULLET_NUMBER2 = '02';
export const CRUDE_SECTION_WHY_BULLET_SUBTITLE2 = 'Having worked in both tech and design roles, I was exposed to design systems from multiple perspectives, which evoked an inherent interest in me.';
export const CRUDE_SECTION_WHY_BULLET_NUMBER3 = '03';
export const CRUDE_SECTION_WHY_BULLET_SUBTITLE3 = 'This course provided me an opportunity to explore, experiment, and learn, which is necessary in the process of creating a design system.';

export const CRUDE_SECTION_STUDY_TITLE = 'Design systems study';
export const CRUDE_SECTION_STUDY_CARD_LABEL1 = 'Material 3.0 by Google';
export const CRUDE_SECTION_STUDY_CARD_ALT1 = 'A screenshot of Material Design website';
export const CRUDE_SECTION_STUDY_CARD_LABEL2 = 'Mailchimp Design System';
export const CRUDE_SECTION_STUDY_CARD_ALT2 = 'A screenshot of Mailchimp design system website';
export const CRUDE_SECTION_STUDY_CARD_LABEL3 = 'Carbon by IBM';
export const CRUDE_SECTION_STUDY_CARD_ALT3 = 'A screenshot of Carbon design system website';
export const CRUDE_SECTION_STUDY_CARD_LABEL4 = 'Atlassian Design System';
export const CRUDE_SECTION_STUDY_CARD_ALT4 = 'A screenshot of Atlassian design system website';
export const CRUDE_SECTION_STUDY_CARD_LABEL5 = 'Fluent by Microsoft';
export const CRUDE_SECTION_STUDY_CARD_ALT5 = 'A screenshot of Fluent design system website';
export const CRUDE_SECTION_STUDY_CARD_LABEL6 = 'Spectrum by Adobe';
export const CRUDE_SECTION_STUDY_CARD_ALT6 = 'A screenshot of Spectrum design system website';
export const CRUDE_SECTION_STUDY_CARD_LABEL7 = 'The Guardian’s Design System';
export const CRUDE_SECTION_STUDY_CARD_ALT7 = 'A screenshot of The Guardian design system website';
export const CRUDE_SECTION_STUDY_CARD_LABEL8 = 'Figma’s Design System';
export const CRUDE_SECTION_STUDY_CARD_ALT8 = 'A screenshot of Figma website';

export const CRUDE_SECTION_STUDY_WHY_TITLE = 'Why study these?';
export const CRUDE_SECTION_STUDY_WHY_SUBTITLE1 = '• Openly available';
export const CRUDE_SECTION_STUDY_WHY_SUBTITLE2 = '• Good documentation';
export const CRUDE_SECTION_STUDY_WHY_SUBTITLE3 = '• Strong principles ';
export const CRUDE_SECTION_STUDY_WHY_SUBTITLE4 = '• Widely used';

export const CRUDE_SECTION_STUDY_LEARNINGS_TITLE = 'Key learnings:';
export const CRUDE_SECTION_STUDY_LEARNINGS_NUMBER1 = '01';
export const CRUDE_SECTION_STUDY_LEARNINGS_SUBTITLE1 = 'Foundational philosophy and principles guide the decisions';
export const CRUDE_SECTION_STUDY_LEARNINGS_NUMBER2 = '02';
export const CRUDE_SECTION_STUDY_LEARNINGS_SUBTITLE2 = 'Color and type scales follow intentional patterns';
export const CRUDE_SECTION_STUDY_LEARNINGS_NUMBER3 = '03';
export const CRUDE_SECTION_STUDY_LEARNINGS_SUBTITLE3 = 'Grid systems allow logical placement of elements in cohesive layouts';
export const CRUDE_SECTION_STUDY_LEARNINGS_NUMBER4 = '04';
export const CRUDE_SECTION_STUDY_LEARNINGS_SUBTITLE4 = 'Leveraging existing mental models and user behaviors is important';

export const CRUDE_SECTION_EXPLORATION_TITLE = 'Exploration';
export const CRUDE_SECTION_EXPLORATION_SHAPE_ALT = 'An illusration of different shape explorations and annotated findings';
export const CRUDE_SECTION_EXPLORATION_COLOR_ALT = 'An illustration of different color explorations and annotated findings';
export const CRUDE_SECTION_EXPLORATION_SPACING_ALT = 'An illustration of different spacing unit explorations and annotated findings';

export const CRUDE_SECTION_PRINCIPLES_TITLE = 'Principles';
export const CRUDE_SECTION_PRINCIPLES_CONTENT_NUMBER1 = '01';
export const CRUDE_SECTION_PRINCIPLES_CONTENT_TITLE1 = 'Bold';
export const CRUDE_SECTION_PRINCIPLES_CONTENT_SUBTITLE1 = 'Make a lasting visual impact';
export const CRUDE_SECTION_PRINCIPLES_CONTENT_NUMBER2 = '02';
export const CRUDE_SECTION_PRINCIPLES_CONTENT_TITLE2 = 'Versatile';
export const CRUDE_SECTION_PRINCIPLES_CONTENT_SUBTITLE2 = 'Adaptive for everything';
export const CRUDE_SECTION_PRINCIPLES_CONTENT_NUMBER3 = '03';
export const CRUDE_SECTION_PRINCIPLES_CONTENT_TITLE3 = 'Extraordinary';
export const CRUDE_SECTION_PRINCIPLES_CONTENT_SUBTITLE3 = 'Be unique with simplicity';

export const CRUDE_SECTION_MOODBOARD_TITLE = 'Moodboard';
export const CRUDE_SECTION_MOODBOARD_ALT = 'A snapshot of the moodboard referencing to neubrutalist UI style';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_TITLE = 'Visual characteristics of Neubrutalism:';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER1 = '01';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY11 = 'Bold typography';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER2 = '02';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY12 = 'High contrast';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER3 = '03';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY13 = 'Brutal shapes & forms';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER4 = '04';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY21 = 'Stark color palette';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER5 = '05';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY22 = 'Bold borders & shadows';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER6 = '06';
export const CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY23 = 'Strong grid structure';

export const CRUDE_SECTION_TYPE_TITLE = 'Type';
export const CRUDE_SECTION_TYPE_ALT = 'A representation of some characters in Zilla Slab typeface';
export const CRUDE_SECTION_TYPE_WHY_TITLE = 'Why Zilla Slab?';
export const CRUDE_SECTION_TYPE_WHY_NUMBER1 = '01';
export const CRUDE_SECTION_TYPE_WHY_TITLE1 = 'Bold and robust';
export const CRUDE_SECTION_TYPE_WHY_SUBTITLE1 = 'Fits in neubrutalist aesthetic';
export const CRUDE_SECTION_TYPE_WHY_NUMBER2 = '02';
export const CRUDE_SECTION_TYPE_WHY_TITLE2 = 'Five weights';
export const CRUDE_SECTION_TYPE_WHY_SUBTITLE2 = 'Good for creating distinctly visible hierarchy in the type scale';
export const CRUDE_SECTION_TYPE_WHY_NUMBER3 = '03';
export const CRUDE_SECTION_TYPE_WHY_TITLE3 = 'Italic variations available';
export const CRUDE_SECTION_TYPE_WHY_SUBTITLE3 = 'Can be needed for specific use cases, especially for a versatile design system';
export const CRUDE_SECTION_TYPE_WHY_NUMBER4 = '04';
export const CRUDE_SECTION_TYPE_WHY_TITLE4 = 'Open source';
export const CRUDE_SECTION_TYPE_WHY_SUBTITLE4 = 'Free for anyone to use';
export const CRUDE_SECTION_TYPE_SCALE_ALT = 'Type scale offered in Crude design system for mobile and desktop with annotations highlighting key features';

export const CRUDE_SECTION_COLOR_TITLE = 'Color';
export const CRUDE_SECTION_COLOR_ALT = 'Crude color palette with annotations highlighting key features';

export const CRUDE_SECTION_GRIDS_SPACING_TITLE = 'Grids and spacing';
export const CRUDE_SECTION_SPACING_ALT = 'Crude spacing scale displaying spacing units in order with annotations indicating usage logic';
export const CRUDE_SECTION_GRIDS_MOBILE_TITLE = 'Mobile grid system';
export const CRUDE_SECTION_GRIDS_MOBILE_ALT = 'A representation of Crude mobile grid';
export const CRUDE_SECTION_GRIDS_DESKTOP_TITLE = 'Desktop grid system';
export const CRUDE_SECTION_GRIDS_DESKTOP_FLUID_ALT = 'A representation of Crude desktop fluid grid';
export const CRUDE_SECTION_GRIDS_DESKTOP_FIXED_ALT = 'A representation of Crude desktop fixed grid';

export const CRUDE_SECTION_BORDER_ELEVATION_TITLE = 'Border and elevation';
export const CRUDE_SECTION_BORDER_ELEVATION_ALT = 'A representation of Crude border and elevation units with annotations highlighting key features';

export const CRUDE_SECTION_ACCESSIBILITY_TITLE = 'Accessibility';
export const CRUDE_SECTION_ACCESSIBILITY_ALT = 'A snapshot of accessibility considerations in Crude design system';
export const CRUDE_SECTION_ACCESSIBILITY_COLOR_ALT = 'A snapshot of color contrast accessibility sheet of Crude indicating compliance with WCAG 2.2 (AA) standards';
export const CRUDE_SECTION_ACCESSIBILITY_OTHER_ALT = 'Representation of minimum tappable area and smallest legible text style in Crude';

export const CRUDE_SECTION_COMPONENTS_TITLE = 'Components';
export const CRUDE_SECTION_COMPONENTS_CONTENT = 'Building on top of the foundations, I created basic interface components (including variants) for mobile and desktop.';
export const CRUDE_SECTION_COMPONENTS_BUTTON_ALT = 'A snapshot of the button component and its variants';

export const CRUDE_SECTION_PATTERNS_TITLE = 'Patterns';
export const CRUDE_SECTION_PATTERNS_ALT = 'A snapshot of some of the patterns created using components of Crude design system';

export const CRUDE_SECTION_WEBSITE_TITLE = 'Concept website';
export const CRUDE_SECTION_WEBSITE_CONTENT = 'To display how these components and resulting patterns work together, I designed a concept website of the design system.';
export const CRUDE_SECTION_WEBSITE_DESKTOP_ALT = 'An illustration of Crude design system concept website on a laptop';
export const CRUDE_SECTION_WEBSITE_DESKTOP_LABEL = 'Desktop prototype ↗';
export const CRUDE_SECTION_WEBSITE_MOBILE_ALT = 'An illustration of Crude design system concept website on a mobile';
export const CRUDE_SECTION_WEBSITE_MOBILE_LABEL = 'Mobile prototype ↗';

export const CRUDE_SECTION_LEARNINGS_TITLE = 'Learnings';
export const CRUDE_SECTION_LEARNINGS_NUMBER1 = '01';
export const CRUDE_SECTION_LEARNINGS_SUBTITLE1 = 'A design system is never finished, it is always evolving.';
export const CRUDE_SECTION_LEARNINGS_NUMBER2 = '02';
export const CRUDE_SECTION_LEARNINGS_SUBTITLE2 = 'Specific use cases of components and patterns provide direction for a design system.';
export const CRUDE_SECTION_LEARNINGS_NUMBER3 = '03';
export const CRUDE_SECTION_LEARNINGS_SUBTITLE3 = 'Foundational design decisions multiply (literally) with a growing design system.';

export const CRUDE_SECTION_WHAT_IF_TITLE = 'What if I had more time?';
export const CRUDE_SECTION_WHAT_IF_CONTENT = 'While I did manage to achieve a lot in a limited timeframe, here are a few key steps I would love to take if there was more room to do.';
export const CRUDE_SECTION_WHAT_IF_NUMBER1 = '01';
export const CRUDE_SECTION_WHAT_IF_SUBTITLE1 = 'Create more components and patterns to make the library more comprehensive';
export const CRUDE_SECTION_WHAT_IF_NUMBER2 = '02';
export const CRUDE_SECTION_WHAT_IF_SUBTITLE2 = 'Extend the design system to other devices such as tablet, watch, and TV';
export const CRUDE_SECTION_WHAT_IF_NUMBER3 = '03';
export const CRUDE_SECTION_WHAT_IF_SUBTITLE3 = 'More detailed examples and documentation for each component';
export const CRUDE_SECTION_WHAT_IF_NUMBER4 = '04';
export const CRUDE_SECTION_WHAT_IF_SUBTITLE4 = 'Tokenize foundations such as text styles, color styles, and spacing units';
export const CRUDE_SECTION_WHAT_IF_NUMBER5 = '05';
export const CRUDE_SECTION_WHAT_IF_SUBTITLE5 = 'Developer hand-off to create components in code';






export const ABOUT_ALT = 'A picture of Shaunak';
export const ABOUT_GREETING = 'Hi there!';
export const ABOUT_INTRO_TITLE = 'I am Shaunak.';
export const ABOUT_INTRO_CONTENT1 = 'I consider myself a strategic problem solver with a passion for consistency and seamless experiences that balance aesthetics and functionality.';
export const ABOUT_INTRO_CONTENT2 = 'I’ve honed my expertise in product design, bringing a unique perspective on design systems, product development, and user interface challenges. My master’s degree in Human-Computer Interaction (HCI) provides me with a deep understanding of the end-to-end design process, ensuring user-centered solutions. Additionally, my background in frontend development enhances my ability to bridge design and technical implementation seamlessly.';
export const ABOUT_INTRO_CONTENT3 = 'I’ve worked across industries like fintech, travel/e-commerce, and edtech, delivering high-impact designs that drive user engagement and satisfaction. My diverse experience enables me to collaborate effectively with cross-functional teams, ensuring a faster and more efficient product development process.';
export const ABOUT_INTRO_CONTENT4 = 'Currently, I’m seeking full-time or contract product design opportunities in the United States and am open to relocation.';
export const ABOUT_RESUME_LINK_LABEL = 'View Resume';
export const ABOUT_LINKEDIN_LINK_LABEL = 'Connect on LinkedIn';

export const ABOUT_VALUES_TITLE = 'Design principles';
export const ABOUT_VALUES_BIAS_TITLE = 'Bias for action';
export const ABOUT_VALUES_BIAS_SUBTITLE = 'Being proactive and outcome oriented enables me to drive swift execution.';
export const ABOUT_VALUES_CURIOSITY_TITLE = 'Curiosity';
export const ABOUT_VALUES_CURIOSITY_SUBTITLE = 'I ask questions by holding a curious mindset to understand the problem and the context.';
export const ABOUT_VALUES_COLLABORATION_TITLE = 'Collaboration';
export const ABOUT_VALUES_COLLABORATION_SUBTITLE = 'I foster open communication and teamwork by collaborating with various stakeholders.';
export const ABOUT_VALUES_STRATEGY_TITLE = 'Strategy';
export const ABOUT_VALUES_STRATEGY_SUBTITLE = 'I use data driven insights to create experiences that not only solve user problems but also align with business goals.';
export const ABOUT_VALUES_TITLE1 = 'Strategic problem solving';
export const ABOUT_VALUES_SUBTITLE1 = 'I dig deep into problems by holding a curious mindset, uncovering root causes, and fully understanding the context before creating solutions. For me, design is a means to solve user and business problems.';
export const ABOUT_VALUES_TITLE2 = 'Collaborative ownership';
export const ABOUT_VALUES_SUBTITLE2 = 'I actively engage with stakeholders and collaborators by involving them in my process. This ensures alignment, shared ownership, and a collective focus on solving problems and achieving success.';
export const ABOUT_VALUES_TITLE3 = 'Constraint management';
export const ABOUT_VALUES_SUBTITLE3 = 'I understand and address constraints early by communicating effectively with stakeholders. This enables me to adopt a pragmatic approach, ensuring solutions are both feasible and focused on successful delivery.';
export const ABOUT_VALUES_TITLE4 = 'Product vision';
export const ABOUT_VALUES_SUBTITLE4 = 'I think beyond individual projects, proactively ensuring my solutions fit seamlessly into the broader product and user experience. I take initiative to align my work with the overall system and long-term vision.';


export const ABOUT_FAQ_TITLE = 'Frequently Asked Questions';
export const ABOUT_FAQ_BULLET_TITLE1 = 'Where are you currently based?';
export const ABOUT_FAQ_BULLET_SUBTITLE1 = 'Currently, I am based in Washington, DC. I am in the EST time zone.';
export const ABOUT_FAQ_BULLET_TITLE2 = 'Are you willing to relocate?';
export const ABOUT_FAQ_BULLET_SUBTITLE2 = 'Yes, I am absolutely willing to make the move for the right opportunity.';
export const ABOUT_FAQ_BULLET_TITLE3 = 'What roles are you looking for?';
export const ABOUT_FAQ_BULLET_SUBTITLE3 = 'I am open to work as a User Experience Designer, Product Designer, User Interface Designer, or User Experience Strategist.';
export const ABOUT_FAQ_BULLET_TITLE4 = 'What is your work eligibility?';
export const ABOUT_FAQ_BULLET_SUBTITLE4 = 'I am currently on F1 visa with STEM OPT work authorization. Effectively, I am eligible to work in the United States without sponsorship for 3 years.';

export const ABOUT_TESTIMONIALS_TITLE = 'Mentorship';
export const ABOUT_TESTIMONIAL_TITLE1 = 'Made me feel better prepared';
export const ABOUT_TESTIMONIAL_SUBTITLE1 = "Shaunak is really approachable and has been a great help to me. He shared candidly and generously about his experience in graduate school. He answered all my questions regarding electives and his experiences with design hackathons. It made me feel better prepared for what to expect in graduate school. I'm very thankful for his guidance and support.";
export const ABOUT_TESTIMONIAL_NAME1 = 'Ming Rui Lee';
export const ABOUT_TESTIMONIAL_ALT1 = 'Picture of Ming Rui Lee';
export const ABOUT_TESTIMONIAL_TITLE2 = 'Passionate and knowledgeable';
export const ABOUT_TESTIMONIAL_SUBTITLE2 = 'I reached out to Shaunak on LinkedIn after coming across a UX article written by him on Medium. He was really approachable and eager to help me with any questions I had. His experience and practical tips have been truly insightful for someone new to the field. He has also been extremely supportive and encouraging. His patience and willingness to answer my endless questions have been invaluable to me. I highly recommend Shaunak to anyone seeking a passionate and knowledgeable mentor in the field of UX design.';
export const ABOUT_TESTIMONIAL_NAME2 = 'Saloni Mehta';
export const ABOUT_TESTIMONIAL_ALT2 = 'Picture of Saloni Mehta';
export const ABOUT_TESTIMONIAL_TITLE3 = 'Helped me make a smart decision';
export const ABOUT_TESTIMONIAL_SUBTITLE3 = "I’m extremely grateful to Shaunak for his guidance and advice on choosing an HCI graduate school. He explained all the pros and cons of different options and helped me weigh them according to my career goals and preferences. He made the process much easier and clearer for me and helped me make a smart decision.";
export const ABOUT_TESTIMONIAL_NAME3 = 'Kevin Thakkar';
export const ABOUT_TESTIMONIAL_ALT3 = 'Picture of Kevin Thakkar';
export const ABOUT_TESTIMONIAL_TITLE4 = 'Provided me with a comprehensive understanding';
export const ABOUT_TESTIMONIAL_SUBTITLE4 = 'I recently received guidance from Shaunak regarding the HCI program at UMD, and I must say it was an invaluable experience. His review of the course, highlighting its practicality in the industry provided me with a comprehensive understanding of what to expect. This guidance played a pivotal role in shaping my decision to enrol in the program and planning the next steps in my career.';
export const ABOUT_TESTIMONIAL_NAME4 = 'Gayatri Kamath';
export const ABOUT_TESTIMONIAL_ALT4 = 'Picture of Gayatri Kamath';
export const ABOUT_TESTIMONIAL_TITLE5 = 'Friendly and welcoming';
export const ABOUT_TESTIMONIAL_SUBTITLE5 = 'Shaunak is really friendly and welcoming. He has never hesitated to give out advice. His guidance encouraged me to pursue my graduate studies in UX domain. He was patient in answering all my queries regarding the admission process, portfolio, and opportunities after graduate school. His advice has been crucial in navigating my career.';
export const ABOUT_TESTIMONIAL_NAME5 = 'Jessica Liu';
export const ABOUT_TESTIMONIAL_ALT5 = 'Picture of Jessica Liu';
export const ABOUT_TESTIMONIAL_TITLE6 = 'Prompt responses and invaluable guidance';
export const ABOUT_TESTIMONIAL_SUBTITLE6 = "I highly appreciate Shaunak's prompt responses and invaluable guidance; they have been very helpful. Even with just our brief conversation about HCI courses, I found the information to be extremely useful. I am looking forward to the opportunity of meeting him in person and furthering our interaction.";
export const ABOUT_TESTIMONIAL_NAME6 = 'Samita Prakash';
export const ABOUT_TESTIMONIAL_ALT6 = 'Picture of Samita Prakash';
export const ABOUT_TESTIMONIAL_TITLE7 = 'An absolute lifesaver';
export const ABOUT_TESTIMONIAL_SUBTITLE7 = 'I thank Shaunak for being an absolute lifesaver! From the day we connected on LinkedIn, he has been so helpful in answering all my doubts related to master’s in HCI - may it be faculty, courses, or life in the US. His feedback helped me throughout the process. I will be attending UMD starting fall 2023 and I couldn’t have done this without his support.';
export const ABOUT_TESTIMONIAL_NAME7 = 'Shaunak Mirashi';
export const ABOUT_TESTIMONIAL_ALT7 = 'Picture of Shaunak Mirashi';
export const ABOUT_TESTIMONIAL_TITLE8 = 'Calm and reassuring';
export const ABOUT_TESTIMONIAL_SUBTITLE8 = "Shaunak has been a great mentor and guided me really well throughout the application process for the HCI master's program in the US. He gave me useful advice for making a good UX portfolio website. Moreover, his calm and reassuring nature helped me during anxious times. All in all, he has been an incredibly supportive mentor and I am highly thankful to have had the opportunity to interact with him.";
export const ABOUT_TESTIMONIAL_NAME8 = 'Mrunmai Abhyankar';
export const ABOUT_TESTIMONIAL_ALT8 = 'Picture of Mrunmai Abhyankar';
export const ABOUT_TESTIMONIAL_TITLE9 = 'Incredibly supportive';
export const ABOUT_TESTIMONIAL_SUBTITLE9 = 'I came across Shaunak through his blog on Medium. It was one of the most descriptive articles I had seen on the SOP writing process for HCI. Additionally, he was incredibly supportive and provided me with guidance about the university, the admission process, and future prospects in the UX industry, which was super helpful!';
export const ABOUT_TESTIMONIAL_NAME9 = 'Natasha Girish';
export const ABOUT_TESTIMONIAL_ALT9 = 'Picture of Natasha Girish';

export const ABOUT_OUTSIDE_WORK_TITLE = 'Life outside work';

export const ABOUT_ADOBE_STUDENT_AMBASSADOR_TITLE = 'Adobe Student Ambassador';
export const ABOUT_ADOBE_STUDENT_AMBASSADOR_SUBTITLE = 'In this role, I host events to spread design awareness and promote Adobe Creative Cloud tools.';
export const ABOUT_ADOBE_STUDENT_AMBASSADOR_ALT = 'A picture of Shaunak speaking about his role as an Adobe Student Ambassador';

export const ABOUT_COOKING_TITLE = 'Cooking';
export const ABOUT_COOKING_SUBTITLE = 'As much as I enjoy eating, I do also enjoy cooking delicious food.';
export const ABOUT_COOKING_ALT = 'A picture of spaghetti cooked by Shaunak';

export const ABOUT_DRIVING_TITLE = 'Exploring the open road';
export const ABOUT_DRIVING_SUBTITLE = 'Fueling my passion for driving with adventurous road trips';
export const ABOUT_DRIVING_ALT = 'A picture of Shaunak while driving';

export const ABOUT_FORMULA1_TITLE = 'Engaging with Formula 1';
export const ABOUT_FORMULA1_SUBTITLE = 'Staying captivated by the speed, strategy, and thrill of Formula 1';
export const ABOUT_FORMULA1_ALT = 'A picture of Formula 1 cars on a race track';

export const ABOUT_MENTORSHIP_TITLE = 'Mentoring aspiring designers';
export const ABOUT_MENTORSHIP_SUBTITLE = 'Sharing my experiences to guide apsiring product and UX designers';
export const ABOUT_MENTORSHIP_ALT = 'A collage of photos of some people Shaunak has mentored';

export const ABOUT_WRITING_TITLE = 'Writing & sharing ideas ↗';
export const ABOUT_WRITING_SUBTITLE = 'Exploring tech and design topics by writing articles on Medium';
export const ABOUT_WRITING_ALT = 'A picture of laptop on a desk';




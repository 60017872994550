
export const HOMEPAGE_NAME = 'Shaunak Bhanarkar';
export const HOMEPAGE_ROLE = 'Product designer crafting purposeful experiences with data-driven insights and systems thinking.';
export const HOMEPAGE_STATUS1 = '✨ Actively seeking full time opportunities';
export const HOMEPAGE_STATUS2 = '🚀 Open to relocation';

export const FEATURED_SECTION_HEADING = 'Featured work';
export const COMPETITION_SECTION_HEADING = 'Accolades';

export const PROJECT_ROCKET_TITLE = 'Rocket Mortgage Tasks';
export const PROJECT_ROCKET_SUBTITLE = 'Streamlining the mortgage process for 290,000+ users by reducing friction points and simplifying task flows';
export const PROJECT_ROCKET_TAG1 = 'Fintech';
export const PROJECT_ROCKET_TAG2 = 'Product design';
export const PROJECT_ROCKET_TAG3 = 'Redesign';
export const PROJECT_ROCKET_ALT = 'An illustration of the redesigned Rocket Mortgage To-do Page';

export const PROJECT_CRUDE_TITLE = 'Crude Design System — 800+ users';
export const PROJECT_CRUDE_SUBTITLE = 'Creating a general purpose, neubrutalist design system for web platforms';
export const PROJECT_CRUDE_TAG1 = 'Design system';
export const PROJECT_CRUDE_TAG2 = 'UI design';
export const PROJECT_CRUDE_ALT = 'An illustration depicting color palette, typography, components, and accessibility sheet of Crude design system';

export const PROJECT_OYO_TITLE = 'Rate Plans — boosted CxR by 20%';
export const PROJECT_OYO_SUBTITLE = 'Empowering 50M+ users with flexible booking choices and driving conversion on a hotel booking app';
export const PROJECT_OYO_TAG1 = 'Travel';
export const PROJECT_OYO_TAG2 = 'Product design';
export const PROJECT_OYO_TAG3 = 'New feature';
export const PROJECT_OYO_ALT = 'An illustration of the OYO app depicting carousel of room category widget';

export const PROJECT_HOMETOWN_HEROES_COMMUNITY_TITLE = 'Hometown Heroes Community — over 90% user satisfaction rate';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_SUBTITLE = 'Facilitating career growth for 3,000+ musicians through a purpose-designed community platform';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_TAG1 = 'Music';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_TAG2 = '0 → 1';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_TAG3 = 'SaaS';
export const PROJECT_HOMETOWN_HEROES_COMMUNITY_ALT = 'An illustration of the home page of Hometown Heroes Community';

export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_TITLE = 'Design Tokens';
export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_SUBTITLE = 'Building a design token system from the ground up for a web platform';
export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_TAG1 = 'SaaS';
export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_TAG2 = 'UI design';
export const PROJECT_HOMETOWN_HEROES_DESIGN_SYSTEM_ALT = 'An illustration depicting tokens used in Hometown Heroes Community';

export const PROJECT_COMFORT_OS_TITLE = 'Comfort OS — 50+ users ↗';
export const PROJECT_COMFORT_OS_SUBTITLE = 'Designing system level interface and brand identity for a smart home device operating system';
export const PROJECT_COMFORT_OS_TAG1 = 'UI design';
export const PROJECT_COMFORT_OS_TAG2 = 'Brand design';
export const PROJECT_COMFORT_OS_ALT = 'An illustration of Comfort OS on a Nest Hub';


export const COMPETITION_USABILATHON_TITLE = '1st place - Usabilathon 2022';
export const COMPETITION_USABILATHON_SUBTITLE = 'Increasing user engagement on the HelloFresh app';
export const COMPETITION_USABILATHON_TAG1 = 'Food delivery';
export const COMPETITION_USABILATHON_ALT = 'An illustration of the redesigned Hello Fresh app';

export const COMPETITION_USABILATHON2024_TITLE = '1st place - Usabilathon 2024';
export const COMPETITION_USABILATHON2024_SUBTITLE = 'Increasing user acquisiton and retention on the GoToll app';
export const COMPETITION_USABILATHON2024_TAG1 = 'Transport';
export const COMPETITION_USABILATHON2024_ALT = 'An illustration of the redesigned GoToll app';


export const COMPETITION_INFO_CHALLENGE_TITLE = '1st place - Info Challenge 2023';
export const COMPETITION_INFO_CHALLENGE_SUBTITLE = 'Enhancing a visualization tool for broader usability and accessibility';
export const COMPETITION_INFO_CHALLENGE_TAG1 = 'Data visualization';
export const COMPETITION_INFO_CHALLENGE_ALT = 'An illustration of the redesigned Pygmalion tool';

export const COMPETITION_PROTOTHON_TITLE = '2nd place - Protothon 2023';
export const COMPETITION_PROTOTHON_SUBTITLE = 'Designing an AI-driven solution to offer personalized support new mothers';
export const COMPETITION_PROTOTHON_TAG1 = 'Femtech';
export const COMPETITION_PROTOTHON_TAG2 = 'AI';
export const COMPETITION_PROTOTHON_ALT = 'An illustration of a concept app for new mothers';

export const COMPETITION_MAKEATHON_TITLE = '3rd place - Makeathon 2023';
export const COMPETITION_MAKEATHON_SUBTITLE = 'Designing an AI-driven solution for managing mental wellbeing';
export const COMPETITION_MAKEATHON_TAG1 = 'Mental wellness';
export const COMPETITION_MAKEATHON_TAG2 = 'AI';
export const COMPETITION_MAKEATHON_ALT = 'An illustration of a concept mental health app for young adults';

export const TESTIMONIAL_SECTION_HEADING = 'Commendations';
export const TESTIMONIAL_TITLE1 = 'Curious and driven';
export const TESTIMONIAL_SUBTITLE1 = "Shaunak has done well in his time as an intern and has made meaningful contributions within the RMO team. He is curious and driven, traits that are especially great to have early in his career. The future is bright for Shaunak and I’m glad he took advantage of all the opportunities to learn and further his craft during his time at Rocket!";
export const TESTIMONIAL_NAME1 = 'Craig Zdanowicz';
export const TESTIMONIAL_POSITION1 = 'Director of Design, Rocket Companies';
export const TESTIMONIAL_ALT1 = 'Picture of Craig Zdanowicz';
export const TESTIMONIAL_TITLE2 = 'Gracefully dealt with constraints';
export const TESTIMONIAL_SUBTITLE2 = "Shaunak hit the ground running in his internship. He methodically learned about a complicated mortgage process in his short time at Rocket. He gracefully dealt with multiple constraints and changing tech requirements. He continually asked thoughtful questions and sought feedback from multiple designers and the Product Manager to make sure his designs met the requirements. He was a pleasure to work with and be his mentor.";
export const TESTIMONIAL_NAME2 = 'Erin Knapp';
export const TESTIMONIAL_POSITION2 = 'Sr Experience Designer, Rocket Companies';
export const TESTIMONIAL_ALT2 = 'Picture of Erin Knapp';
export const TESTIMONIAL_TITLE3 = 'Collaborative and detail oriented';
export const TESTIMONIAL_SUBTITLE3 = "Throughout his internship with the Design team at Rocket Mortgage, Shaunak consistently demonstrated a remarkable dedication to his work and a strong passion for design. Shaunak's creativity and eye for detail were evident. He delivered high quality design solutions that exceed our expectations. One of Shaunak's standout qualities his collaborative nature. He seamlessly integrated into our design team effectively communicating his ideas. In addition to his technical skills, Shaunak's passions for design and willingness to learn were evident as he consistently sought opportunities to enhance his knowledge and actively participate in team discussions. Based on his performance as a design intern at Rocket Mortgage, I have no doubt Shaunak has a bright future ahead. Any company would be lucky to have him.";
export const TESTIMONIAL_NAME3 = 'Tyler Rowland';
export const TESTIMONIAL_POSITION3 = 'Sr Product Manager, Rocket Companies';
export const TESTIMONIAL_ALT3 = 'Picture of Tyler Rowland';
export const TESTIMONIAL_TITLE4 = 'Active and vocal';
export const TESTIMONIAL_SUBTITLE4 = "From day one, Shaunak was an active and vocal part of our functional team. Focused on design challenges in the mortgage origination space, we had to help users navigate a particularly complex and occasionally frustrating part of the mortgage process. Shaunak not only quickly versed himself in the technical aspects of the experience but soon began applying design principles and helping us craft solutions that would provide clarity and guidance for clients. Even with all the competence and insight he brings, Shaunak always remains flexible and humble. I've rarely seen someone with his experience so clearly ready to work as a design professional.";
export const TESTIMONIAL_NAME4 = 'Alee Karim';
export const TESTIMONIAL_POSITION4 = 'Sr UX Writer, Rocket Companies';
export const TESTIMONIAL_ALT4 = 'Picture of Alee Karim';
export const TESTIMONIAL_TITLE5 = 'Inquisitive and passionate';
export const TESTIMONIAL_SUBTITLE5 = "It was a pleasure working with Shaunak. He directly reported to me in the OYO Consumer Design pod. Although his stint with OYO was short, but his contribution was commendable. He was keen on his true passion for design and increasing his knowledge in design every day. He is an inquisitive person, and this is evident from the amount of questions he asks at every step. His ability to go to the root of a problem rather than solve it from the top makes him a good designer. Shaunak has successfully delivered the most complex design projects at OYO and demonstrated how to design a product with a great experience that the customer loves. Shaunak will be a great asset to any organization that is truly dedicated to providing an immersive experience to its customers.";
export const TESTIMONIAL_NAME5 = 'Sandeep Parashar';
export const TESTIMONIAL_POSITION5 = 'Director of Product Design, Oyo';
export const TESTIMONIAL_ALT5 = 'Picture of Sandeep Parashar';
export const TESTIMONIAL_TITLE6 = 'Effective communicator';
export const TESTIMONIAL_SUBTITLE6 = "I had the pleasure of collaborating with Shaunak and his team this past year. I was immediately impressed by Shaunak's energy and enthusiasm as he spearheaded the project, listening to my ideas and goals, and coming up with solutions. After each meeting on the project, I felt inspired and motivated. He conveyed excitement about the project and took the initiative to familiarize himself with the key aspects of my business. Shaunak is an effective communicator, clearly presenting the project's progress and always ensuring that my thoughts and preferences were addressed. When I would present ideas, he would create excellent visual designs. With each meeting, I saw more of my ideas honed, improved and brought to life digitally. Shaunak is also great with project management and deadlines, always getting things to me on time. He worked closely with me and made sure that my vision remained the focus while helping me to see alternative ways to implement the foundational design of the website and dealing with any problems we encountered. Thanks to Shaunak's leadership, all of the stated project goals were successfully met. I now have a great start for the next chapter of my business.";
export const TESTIMONIAL_NAME6 = 'Mark Rogers';
export const TESTIMONIAL_POSITION6 = 'Client, Hometown Heroes';
export const TESTIMONIAL_ALT6 = 'Picture of Mark Rogers';